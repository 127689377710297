<template>
  <div
    class="d-flex justify-content-between align-items-center"
    @click="$emit('click', statusId)"
  >
    <div class="text-center">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ statisticTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statusId: {
      type: String,
      required: true,
    },
  },
}
</script>
