<template>
  <b-dropdown
    id="dropdown-buttons"
    :variant="variant"
    :toggle-class="!btnText ? 'p-0' : ''"
    :no-caret="!useCaret"
    :right="isRightAlign"
    :class="!btnText ? 'h-100 ml-1' : ''"
    :text="btnText"
  >
    <template
      #button-content
      v-if="!btnText"
    >
      <feather-icon
        icon="GridIcon"
        size="19"
        class="m-50"
      />
    </template>

    <b-dropdown-item
      v-if="$can('add', permissionSubjects.GroupMember)"
      v-b-modal.bulk-add-to-group-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Add To Group
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('remove', permissionSubjects.GroupMember)"
      v-b-modal.bulk-remove-from-group-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Remove From Group
    </b-dropdown-item>
    <b-dropdown-item
      v-if="canSendBulkCommunication"
      v-b-modal.bulk-communication-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Communication
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('update', permissionSubjects.ChildApplicationStatus)"
      v-b-modal.bulk-change-status
      :disabled="markedElements.length < 1"
    >
      Bulk Change Status
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('create', permissionSubjects.Charge)"
      v-b-modal.bulk-adding-charge-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Charge
    </b-dropdown-item>
    <b-dropdown-item
      v-b-modal.bulk-generate-letter-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Generate Letter
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('assign', permissionSubjects.Tags)"
      v-b-modal.bulk-tag-assign-modal
      :disabled="markedElements.length < 1"
    >
      Bulk Tag Assign
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$can('approve' || 'reject', permissionSubjects.ChildApplicationStep)"
      v-b-modal.bulk-change-step-status
      :disabled="markedElements.length < 1 || !filterDataStep"
    >
      Bulk Approve/Reject Step
    </b-dropdown-item>
    <b-dropdown-item
      v-if="canExportPDF"
      v-b-modal.export-pdf-modal
      :disabled="markedElements.length < 1"
    >
      <span>Export Statement</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    btnText: {
      type: String,
      default: '',
    },
    isRightAlign: {
      type: Boolean,
      default: false,
    },
    useCaret: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      filterDataStep: 'app-applications/getFilterDataStep',
    }),
    canExportPDF() {
      return this.$can('export', permissionSubjects.ExportPDF)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
  },
  methods: {
    openSaveReportModal(isSaveAs) {
      this.$emit('openSaveReportModal', isSaveAs)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
