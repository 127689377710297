<template>
  <div>
    <b-card
      no-body
    >
      <!-- Header-->
      <div
        class="header d-flex align-items-center justify-content-end my-2"
      >
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1 mb-0">Search</label>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              class="w-25"
            />
            <view-customizer />
            <applications-bulk-actions-buttons
              :marked-elements="markedElements"
              :is-right-align="true"
            />
          </div>
        </b-col>
      </div>

      <Spinner
        v-if="isLoading"
      />

      <div
        v-else
      >
        <div
          class="statuses-list"
        >
          <status-card
            v-for="status in statusesList"
            :key="status.id"
            :status-name="status.name"
            :status-id="status.id"
          />
        </div>
      </div>

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end p-2 pr-3"
        >
          <applications-bulk-actions-buttons
            :marked-elements="markedElements"
            :btn-text="'Bulk Actions'"
            :use-caret="true"
            :variant="'outline-danger'"
          />
        </b-col>

      </b-row>

    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BFormInput, BRow, BCol,
} from 'bootstrap-vue'

import store from '@/store'
import StatusCard
  from '@/views/admin/applications/applications-list/applications-kanban-view/application-status-card/StatusCard.vue'
import ViewCustomizer from '@/views/components/applications-viewing-options/ViewCustomizer.vue'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { permissionSubjects } from '@/libs/acl/constants'
import ApplicationsBulkActionsButtons
  from '@/views/admin/applications/applications-list/components/ApplicationsBulkActionsButtons.vue'

let debounceTimeout = null

export default {
  name: 'ApplicationsKanbanView',
  components: {
    ApplicationsBulkActionsButtons,
    BCard,
    BFormInput,
    BRow,
    BCol,

    StatusCard,
    ViewCustomizer,
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      selectedViewColumns: 'app-applications/getSelectedViewColumns',
      statusesList: 'app-applications/getStatusesList',
      forceReload: 'app-applications/getForceReload',
      markedElements: 'app-applications/getMarkedElements',
      selectedViewTagsselectedViewTags: 'app-applications/getSelectedViewTags',
      filterDataStep: 'app-applications/getFilterDataStep',
    }),
    searchQuery: {
      get() {
        return store.getters['app-applications/getSearchQuery']
      },
      set(val) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          store.commit('app-applications/SET_SEARCH_QUERY', val)
        }, 500)
      },
    },
  },
  watch: {
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.$route.name, 'searchQuery', val)
      }
    },
  },
  beforeDestroy() {
    store.commit('app-applications/RESET_ALL_APPLICATIONS_BY_STATUSES')
  },
}
</script>

<style lang="scss" scopped>
.statuses-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 400px;
}
.new-status {
  align-self: start;
  background: #FBFBFC;
  border: 1px solid #D8D6DE;
  border-radius: 8px;
  width: 270px;
  flex-basis: 270px;
  flex-shrink: 0;
  margin: 0 10px;
}
</style>
