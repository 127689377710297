<template>
  <ValidationObserver
    ref="bulkRemoveFromGroupModal"
  >
    <b-modal
      id="bulk-remove-from-group-modal"
      ref="bulk-remove-from-group-modal"
      centered
      title="Bulk Remove From Group"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="resetModal"
      @show="preparePlaceholdersData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            v-if="withExecute"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Field with available application ID"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  :placeholder="'Select Field'"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleTargetIdSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Group"
            >
              <validation-provider
                #default="{ errors }"
                name="group"
                rules="required"
              >
                <v-select
                  v-model="selectedGroup"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="availableGroupsList"
                  label="name"
                  value="id"
                  :clearable="false"
                  :placeholder="fieldGroupStatus"
                  :disabled="!targetField && withExecute"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleGroupSelected"
                >
                  <template
                    #option="{ name, type }"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ name }}</span> <em class="mr-2">{{ type }}</em>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isUsersLoading"
            sm="12"
            class="mb-1 d-flex justify-content-center align-items-center"
          >
            <span class="text-muted mr-1">Fetching Available Users</span> <b-spinner />
          </b-col>

          <b-col
            v-if="usersListReadyForAdding.length"
            sm="12"
            class="mb-1"
          >
            <app-collapse>
              <app-collapse-item
                :title="`Available Users (${usersListReadyForAdding.length})`"
              >
                <div
                  v-for="application in usersListReadyForAdding"
                  :key="application.id"
                  class="mb-1"
                >
                  {{ application.user.full_name }}
                </div>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <span class="mr-1 text-muted">{{ usersListReadyForAdding.length }} Available Users</span>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || !usersListReadyForAdding.length"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useBulkAddGroups from '@/views/components/bulk-add-to-group/useBulkAddGroups'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => false,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    groupId: {
      type: String,
      default: '',
    },
    programId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      destinationTypeOptions: [],
      selectedGroup: null,
      selectedApplications: null,
      targetField: null,
      availableGroupsList: [],
      isLoading: false,
      isUsersLoading: false,
      fieldGroupStatus: 'Select Group',
      usersListReadyForAdding: [],
      required,
    }
  },
  setup() {
    const {
      fetchGroups,
      fetchUsers,
      addUsersToGroup,
      removeApplicationsFromGroup,
    } = useBulkAddGroups()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      fetchGroups,
      fetchUsers,
      addUsersToGroup,
      removeApplicationsFromGroup,
      fetchResultsByTargetField,
    }
  },
  methods: {
    closeModal() {
      this.$refs['bulk-remove-from-group-modal'].hide()
    },
    async preparePlaceholdersData() {
      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
      } else {
        const groupFilterParams = {}
        if (this.filterName) {
          groupFilterParams[this.filterName] = this.markedElements
        }
        await this.prepareGroups(groupFilterParams)
      }
    },
    resetModal() {
      this.destinationTypeOptions = []
      this.selectedGroup = null
      this.selectedApplications = null
      this.targetField = null
      this.availableGroupsList = []
      this.usersListReadyForAdding = []
    },
    async handleTargetIdSelected() {
      this.availableGroupsList = []
      this.selectedGroup = null
      this.usersListReadyForAdding = []

      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        if (this.programId) {
          queryParams.program_id = this.programId
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }

      const groupFilterParams = { applicationId: this.selectedApplications }
      await this.prepareGroups(groupFilterParams)
    },
    async prepareGroups(groupFilterParams = {}) {
      // eslint-disable-next-line no-param-reassign
      groupFilterParams.exceptProgramType = 'Program'

      if (this.programId) {
        // eslint-disable-next-line no-param-reassign
        groupFilterParams.programId = this.programId
      }

      this.fieldGroupStatus = 'Loading...'
      this.availableGroupsList = await this.fetchGroups(groupFilterParams).then(res => res)
      this.fieldGroupStatus = 'Select Group'
    },
    handleGroupSelected() {
      this.usersListReadyForAdding = []

      if (this.allElementsChecked && this.groupId) {
        this.handleOptionsSelectedForAllChecked()
      } else {
        this.handleOptionSelectedForMarkedElements()
      }
    },
    async handleOptionSelectedForMarkedElements() {
      this.usersListReadyForAdding = []
      this.isUsersLoading = true
      const formData = {
        applicationId: this.selectedApplications,
        groupId: this.selectedGroup.id,
      }
      if (this.filterName) {
        formData[this.filterName] = this.markedElements
      }

      await this.fetchUsers(formData).then(response => {
        this.usersListReadyForAdding = response
      }).finally(() => {
        this.isUsersLoading = false
      })
    },
    async handleOptionsSelectedForAllChecked() {
      this.usersListReadyForAdding = []

      const formData = {
        groupId: this.groupId,
      }

      await this.fetchUsers(formData).then(response => {
        this.usersListReadyForAdding = response
      })
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.bulkRemoveFromGroupModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.isLoading = true
            this.setGroups()
          } else {
            reject()
          }
        })
      })
    },
    async setGroups() {
      const queryParams = {
        group_id: this.selectedGroup.id,
        application_id: this.usersListReadyForAdding.map(user => user.id),
      }
      this.isLoading = true
      await this.removeApplicationsFromGroup(queryParams)
        .then(response => {
          if (response.status === 200) {
            this.$emit('successfullySend')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
